<template lang="html">
  <div>
    <input
      id="erd"
      type="file"
      accept="image/*"
      style="display:none;"
      @change="onChange"
    >
  </div>
</template>

<script>
import { VueLoggerPlugin } from "@gf/gf-logger";
import Vue from "vue";
import Store from "../../common/store";
import GfContentComponents from "../../GfContentBase";
import UploadServiceMixin from "../../common/UploadServiceMixin.vue";

Vue.use(VueLoggerPlugin);

export default {
	name: "UploadComponent",
	mixins: [GfContentComponents, UploadServiceMixin],

	data() {
		return {
			store: Store,
		};
	},

	mounted: function () {
		this.$gfLogger.logInfo("Mounted Upload Component");

		if (this.inputDisplayIdSelector) {
			this.modalName = this.inputDisplayIdSelector;
			this.bindInputTargetButton(`#${this.modalName}`);
		}

		if (this.inputDisplayClassSelector) {
			this.$gfLogger.logInfo(
				"inputDisplayClassSelector",
				this.inputDisplayClassSelector
			);
			this.modalName = this.inputDisplayClassSelector;
			this.bindInputTargetButton(`.${this.modalName}`);
		}
	},

	methods: {
		onChange(e) {
			var file = e.target.files[0];
			if (file) {
				if (/^image\//i.test(file.type)) {
					this.readFile(file);
				}
			}
		},
		bindInputTargetButton(selector) {
			document.querySelectorAll(selector).forEach((el) => {
				el.addEventListener("click", (e) => {
					e.preventDefault();
					$("#erd").click();
				});
			});
		},

		async uploadProcess(fileData, typeFile) {
			var extension;
			var self = this;
			if (typeFile == "image/jpeg") {
				extension = ".jpg";
			} else if (typeFile == "image/png") {
				extension = ".png";
			} else if (typeFile == "image/gif") {
				extension = ".gif";
			}
			const now = new Date();
			var tempFileName = "LOGO" + this.fileTimeFromDate(now) + extension;

			var data = {
				Context: {
					FundraisingPageId: this.store.state.fundraisingPageId,
				},
				Key: this.configurationKey,
				UserId: this.store.state.userId,
				ContentType: typeFile
			};

			var directUploadResult = await this.$_UploadServiceMixin_getDirectUpload(data);
			var processResult = {
				success: directUploadResult.success,
				savedPath: "",
				fileName: tempFileName,
				fileData: fileData,
				fundraisingPageId: this.store.state.fundraisingPageId,
				eventCampaignId: this.store.state.eventCampaignId,
				formId: this.store.state.formId,
				userId: this.store.state.userId
			};
			if (directUploadResult.success == true) {
				if (directUploadResult.data.AccessKey) {
					processResult.savedPath = this.$_UploadServiceMixin_uploadFile(
						fileData,
						tempFileName,
						directUploadResult,
						typeFile
					);
				}
			}

			window[this.afterProcessCallBack](processResult);
		},

		readFile(file) {
			var self = this;
			var fileTypeClass;
			var reader = new FileReader();
			$(".msg-saving").stop().fadeIn();
			reader.onloadend = function () {
				fileTypeClass = file.type;
				var canvas = document.createElement("canvas");
				EXIF.getData(file, function () {
					var mpImg = new MegaPixImage(file);
					mpImg.render(
						canvas,
						{
							maxWidth: 1024,
							orientation: file.exifdata.Orientation,
						},
						async function () {
							var imageData = canvas.toDataURL("image/jpeg", 0.5);
							var blob = self.dataURItoBlob(imageData);
							await self.uploadProcess(blob, fileTypeClass);
						}
					);
				});
			};

			reader.readAsDataURL(file);
		},

		fileTimeFromDate(date) {
			return date.getTime() * 1e4 + 116444736e9;
		},
		
		dataURItoBlob(dataURI) {
			var byteString;
			if (dataURI.split(",")[0].indexOf("base64") >= 0)
				byteString = atob(dataURI.split(",")[1]);
			else byteString = unescape(dataURI.split(",")[1]);

			var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

			var ia = new Uint8Array(byteString.length);
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			return new Blob([ia], { type: mimeString });
		},
	},
};
</script>

<style lang="css">
</style>
