import UploadComponent from './components/Upload/UploadComponent'
import BlogContentEntry from './components/Content/BlogContentEntry'
import UploadImage from './components/Upload/UploadImage'
import IconPickerComponent from './components/Content/IconPickerComponent'

const components = {
  UploadComponent,
  BlogContentEntry,
  UploadImage,
  IconPickerComponent
}

export default components;